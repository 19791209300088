<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            width="500"
        >
            <v-card class="card-padding">
                <div class="card-form">
                    <div class="header-form mb-5">
                        <div class="text-header">{{deleteForm.header}}</div>
                        <div class="pointer" @click="closeModal">X</div>
                    </div>
                    <div class="delete-title mb-5">
                        {{ deleteForm.title }}
                    </div>
                    <div class="btn-container">
                        <v-btn class="mr-4" @click="submitDelete" color="primary">
                            Delete
                        </v-btn>
                        <v-btn @click="closeModal" color="primary" outlined>
                            Cancel
                        </v-btn>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'DeleteModal',

    props: {
        deleteForm: {
            type: Object,
            default: null
        }
    },

    data () {
        return {
            dialog: false
        }
    },

    methods: {
        async submitDelete () {
            const res = await this.axios.delete(this.deleteForm.url)
            if (res && res.data && res.data.status === 'ok') {
                this.dialog = false
                this.$emit('delete-success')
            } else if (res && res.data && res.data.status === 'error') {
                this.$notify({
                    title: 'Error!',
                    text: res.data.msg,
                    type: 'error'
                })
            }
        },

        closeModal () {
            this.dialog = false
        }
    }
}
</script>

<style lang="scss" scoped>

</style>

<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            width="500"
        >
            <v-card class="card-padding">
                <div class="card-form">
                    <div class="header-form">
                        <div class="text-header">{{headerForm}}</div>
                        <div class="pointer" @click="closeModal">X</div>
                    </div>
                    <v-form
                        ref="form"
                        v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.name"
                                        :rules="inputRules"
                                        label="Name"
                                        :error-messages="errors.name"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-select
                                        v-model="form.groupId"
                                        :items="groupOptions"
                                        label="Group"
                                        item-text="key"
                                        item-value="value"
                                        :error-messages="errors.group"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="6"
                                >
                                    <v-select
                                        v-model="form.classLevel"
                                        :items="levelOptions"
                                        label="Level"
                                        item-text="key"
                                        item-value="value"
                                        :error-messages="errors.group"
                                        multiple
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-select
                                        v-model="form.branchId"
                                        :items="branchOptions"
                                        label="Branch"
                                        item-text="key"
                                        item-value="value"
                                        :error-messages="errors.group"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="mode === 'create'">
                                <v-col cols="12">
                                    <p>Select date</p>
                                    <div class="d-flex d-flex-justify-center">
                                        <v-date-picker
                                            v-model="form.dateList"
                                            multiple
                                        ></v-date-picker>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12">
                                    <p>Select date</p>
                                    <div class="d-flex d-flex-justify-center">
                                        <v-date-picker
                                            v-model="form.date"
                                        ></v-date-picker>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="mb-4">
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.startTime"
                                        :error-messages="errors.startTime"
                                        label="Start time"
                                        type="time"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.endTime"
                                        :error-messages="errors.endTime"
                                        label="End Time"
                                        type="time"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <div class="btn-container">
                                <v-btn class="mr-4" @click="submit" color="primary">
                                    Submit
                                </v-btn>
                                <v-btn @click="closeModal" color="primary" outlined>
                                    Cancel
                                </v-btn>
                            </div>
                        </v-container>
                    </v-form>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'StudentCourseForm',
    props: {
        mode: {
            type: String,
            default: null
        },

        classId: {
            type: Number,
            default: null
        },

        studentId: {
            type: String,
            default: null
        }
    },

    data () {
        return {
            dialog: false,
            valid: false,
            inputRules: [
                v => !!v || 'This field is required'
            ],
            form: {
                name: '',
                branchId: '',
                groupId: '',
                startTime: '',
                endTime: '',
                dateList: [],
                date: '',
                classLevel: []
            },
            branchOptions: [],
            levelOptions: [],
            headerForm: 'Create Class',
            groupOptions: [],
            errors: {}
        }
    },
    methods: {
        notifySuccess (text) {
            this.$notify({
                title: 'Congratulations',
                text: text,
                type: 'success'
            })
        },

        async getBranchOptions () {
            const res = await this.axios.get('branches-select')
            if (res && res.data && res.data.status === 'ok') {
                this.branchOptions = res.data.data
            }
        },

        async getLevelOptions () {
            const res = await this.axios.get('students-level-select')
            if (res && res.data && res.data.status === 'ok') {
                this.levelOptions = res.data.data
            }
        },

        async getGroupOptions () {
            const res = await this.axios.get('group-courses-select')
            if (res && res.data && res.data.status === 'ok') {
                this.groupOptions = res.data.data
            }
        },

        async createClass () {
            const res = await this.axios.post('ep-classes', this.form)
            if (res && res.data && res.data.status === 'ok') {
                this.notifySuccess('Create class success')
                this.$emit('create-success')
                this.closeModal()
            } else if (res && res.data && res.data.status === 'error_inputs') {
                this.errors = res.data.errors
            }
        },

        async editClass () {
            const res = await this.axios.put(`ep-classes/${this.classId}`, this.form)
            if (res && res.data && res.data.status === 'ok') {
                this.notifySuccess('Update class success')
                this.$emit('edit-success')
                this.closeModal()
            } else if (res && res.data && res.data.status === 'error_inputs') {
                this.errors = res.data.errors
            }
        },
        async getStudentClass () {
            const res = await this.axios.get(`ep-classes/${this.classId}`)
            if (res && res.data && res.data.status === 'ok') {
                this.form = res.data.data
            }
        },

        closeModal () {
            this.dialog = false
            this.resetForm()
            this.$emit('close-modal')
        },

        async submit () {
            if (!this.$refs.form.validate()) {
                return
            }

            this.form.classLevel = this.form.classLevel.toString()
            if (this.mode === 'create') {
                await this.createClass()
            } else {
                await this.editClass()
            }
        },

        resetForm () {
            this.$refs.form.reset()
            this.form = {
                name: '',
                branchId: '',
                groupId: '',
                startTime: '',
                endTime: '',
                dateList: [],
                date: '',
                classLevel: []
            }
        }
    },

    watch: {
        async mode (newVal, oldVal) {
            if (newVal === 'create') {
                this.headerForm = 'Create Class'
            } else if (newVal === 'edit') {
                this.headerForm = 'Edit Class'
                await this.getStudentClass()
            }
        }
    },

    async mounted () {
        await this.getBranchOptions()
        await this.getLevelOptions()
        await this.getGroupOptions()
    }
}
</script>

<style lang="scss" scoped>
.form-body {
    padding: 0 12px;
}
</style>

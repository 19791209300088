<template>
    <div>
        <div class="header-page">
            <div class="header-font">
                Class
            </div>
            <div>
                <v-btn color="primary create-btn" @click="createClass">Create</v-btn>
            </div>
        </div>
        <div class="class-filter-container">
            <div class="filter-search mr-4">
                <v-text-field
                    v-model="filterForm.name"
                    label="Name"
                    prepend-icon="mdi-magnify"
                >
                </v-text-field>
            </div>
            <div class="filter-search mr-4">
                <v-menu
                    v-model="datePickerMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateDisplay"
                        label="Date of Class"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="filterForm.date = null"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="filterForm.date"
                        @input="datePickerMenu = false"
                        @change="formatDate(filterForm.date)"
                    ></v-date-picker>
                </v-menu>
            </div>
            <div class="filter-search mr-4">
                <v-select
                    class="filter-search"
                    v-model="filterForm.branchId"
                    :items="branchList"
                    label="Branch"
                    item-text="key"
                    item-value="value"
                ></v-select>
            </div>
            <div>
                <v-btn
                    @click="getClassList"
                    color="primary"
                    outlined
                >
                    Search
                </v-btn>
            </div>
        </div>
        <v-data-table
            :headers="headers"
            :items="classList"
            class="elevation-1"
        >
            <template v-slot:[`item.date`]="{ item }">
                <v-chip
                    :color="getColor(item.date)"
                    dark
                >
                    {{ item.date }}
                </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="text-truncate">
                <a :href="getHref(item)">
                    <v-icon
                        small
                        class="mr-2"
                    >
                        mdi-clipboard-list-outline
                    </v-icon>
                </a>
                <v-icon
                    small
                    class="mr-2"
                    @click="editClass(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteModal(item)"
                >
                    mdi-delete
                </v-icon>
            </div>
            </template>
        </v-data-table>
        <class-form
            ref="classForm"
            :classId="classId"
            :mode="mode"
            @create-success="getClassList"
            @edit-success="getClassList"
            @close-modal="resetValueForm"
        ></class-form>
        <delete-modal
            ref="deleteModal"
            @delete-success="deleteSuccess"
            :deleteForm="deleteForm"
        ></delete-modal>
    </div>
</template>

<script>
import ClassForm from '../components/ClassForm'
import DeleteModal from '../components/DeleteModal'
export default {
    name: 'EpClass',
    components: {
        ClassForm,
        DeleteModal
    },
    data () {
        return {
            classList: [],
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Group', value: 'groupName' },
                { text: 'Date', value: 'date' },
                { text: 'start time', value: 'startTime' },
                { text: 'end time', value: 'endTime' },
                { text: 'Level', value: 'classLevel' },
                { text: 'Branch', value: 'branchName' },
                { text: '', value: 'actions' }
            ],
            classId: null,
            deleteForm: {
                header: 'Delete class',
                title: 'You want to delete class ',
                url: ''
            },
            filterForm: {
                name: '',
                date: '',
                branchId: ''
            },
            dateList: {},
            mode: '',
            datePickerMenu: false,
            branchList: [{
                key: 'All',
                value: null
            }],
            dateDisplay: ''
        }
    },

    methods: {
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            this.dateDisplay = `${day}/${month}/${year}`
        },

        getColor (date) {
            return this.dateList[date]
        },

        async createClass () {
            this.mode = 'create'
            this.$refs.classForm.dialog = true
        },

        async editClass (item) {
            this.mode = 'edit'
            this.classId = item.id
            this.$refs.classForm.dialog = true
        },

        getHref (item) {
            return `/ep-classes/${item.id}/student-booking`
        },

        async deleteSuccess () {
            this.$notify({
                title: 'Congratulations',
                text: 'Delete class success',
                type: 'success'
            })
            await this.getClassList()
        },

        async getClassList () {
            const res = await this.axios.get(`ep-classes?name=${this.filterForm.name}&date=${this.filterForm.date}&branch=${this.filterForm.branchId}`)
            if (res && res.data && res.data.status === 'ok') {
                this.classList = res.data.data
                for (let i = 0; i < this.classList.length; i++) {
                    const epClassData = this.classList[i]
                    if (!this.dateList[epClassData.date]) {
                        const randomColor = Math.floor(Math.random() * 16777215).toString(16)
                        this.dateList[epClassData.date] = `#${randomColor}`
                    }
                }
            }
        },

        resetValueForm () {
            this.mode = ''
            this.classId = null
        },

        deleteModal (item) {
            this.deleteForm.title = `You want to delete class ${item.name} [${item.date}] ?`
            this.deleteForm.url = `ep-classes/${item.id}`
            this.$refs.deleteModal.dialog = true
        },

        async getBranchOptions () {
            const res = await this.axios.get('branches-select')
            if (res && res.data && res.data.status === 'ok') {
                this.branchList = [...this.branchList, ...res.data.data]
                if (localStorage.getItem('branches')) {
                    this.filterForm.branchId = parseInt(localStorage.getItem('branches').split(',')[0])
                }
            }
        }
    },

    async mounted () {
        await this.getBranchOptions()
        await this.getClassList()
    }
}
</script>

<style lang="scss" scoped>
.class-filter-container {
    display: flex;
    align-items: center;

    .filter-search {
        width: 220px;
    }
}
</style>
